<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "App",
    components: {},
};
</script>

<style>
*{
    margin: 0;
    padding: 0;
}
@font-face{
  font-family: font-name;
  /* src:url('@/assets/st.ttc') */
}
</style>
