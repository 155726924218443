import Vue from 'vue'
import App from './App.vue'
import router from './router'
import routerYD from './router/indexYD'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vant from 'vant';
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
import 'vant/lib/index.css'
import ElementUI from 'element-ui'; // 2.1引入结构
import 'element-ui/lib/theme-chalk/index.css'; // 2.2引入样式
import less from 'less'
// 使用组件
import VueVideoPlayer from 'vue-video-player'
// 引入样式
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
Vue.use(VueVideoPlayer)
Vue.use(less)
Vue.use(Vant);
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
import AMap from 'vue-amap' // 引入高德地图

Vue.use(AMap);
AMap.initAMapApiLoader({
  key: '5b4caf68bceedafad80411cd8e3e7d68',  //填写在高德开放平台获取的key
  plugin: [
    'AMap.Autocomplete',
		'AMap.moveAnimation',
		'AMap.Autocomplete',
		'AMap.PlaceSearch',
		'AMap.Scale',
		'AMap.OverView',
		'AMap.ToolBar',
		'AMap.MapType',
		'AMap.PolyEditor',
		'AMap.CircleEditor',
		'MarkerClusterer',
		'AMap.Geolocation',
		'AMap.HeatMap',
		"AMap.DistrictSearch",
		"AMap.Size",
		"AMap.Icon",
		"AMap.Marker",
		"AMap.Pixel",
		"AMap.Polyline",
		"AMap.Geocoder",
  ],
  v: '1.4.4'
});
const clientWidth = document.documentElement.clientWidth
new Vue({
  render: h => h(App),
  router: clientWidth<751 ? routerYD : router,
}).$mount('#app')
