import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [ 
        //首页
        {
            path: '/',
            name:'/',
            component: () => import("@/mobile/Home/HomeIndex.vue"),
        },
        //工业厂房
        {
            path: '/plant',
            name:'/plant',
            component: () => import("@/mobile/Home/PlantIndex.vue")
        },
        //办公楼宇
        {
            path: '/building',
            name:'/building',
            component: () => import("@/mobile/Home/buildingIndex.vue")
        },
        //商业网点
        {
            path: '/business',
            name:'/business',
            component: () => import("@/mobile/Home/businessIndex.vue")
        },
        //电子沙盘
        {
            path: '/sand',
            name:'/sand',
            component: () => import("@/mobile/Home/sandIndex.vue")
        },
        //营商环境
        {
            path: '/atmosphere',
            name:'/atmosphere',
            component: () => import("@/mobile/Home/atmosphereIndex.vue")
        },
        //联系我们
        {
            path: '/contact',
            name:'/contact',
            component: () => import("@/mobile/Home/contactIndex.vue")
        },
        //登录
        {
            path: '/login',
            name:'/login',
            component: () => import("@/mobile/Home/LogIn.vue")
        },
        //我的账户
        {
            path: '/account',
            name:'/account',
            component: () => import("@/mobile/Home/accountIndex.vue")
        },
        //我的收藏
        {
            path: '/collect',
            name:'/collect',
            component: () => import("@/mobile/Home/collectIndex.vue")
        },
          //浏览记录
        {
            path: '/record',
            name:'/record',
            component: () => import("@/mobile/Home/recordIndex.vue")
        },
          //报表订单
        {
            path: '/order',
            name:'/order',
            component: () => import("@/mobile/Home/orderIndex.vue")
        },
          //系统设置
        {
            path: '/set',
            name:'/set',
            component: () => import("@/mobile/Home/setIndex.vue")
        },
        //新闻详情
        {
            path: '/singleNews',
            name:'/singleNews',
            component: () => import("@/mobile/Home/SingleNews.vue")
        },
        //详情
        {
            path: '/details',
            name:'/details',
            component: () => import("@/mobile/Home/detailsIndex.vue")
        },
        //营商环境更多
        {
            path: '/paxton',
            name:'/paxton',
            component: () => import("@/mobile/Home/PaxtonIndex.vue")
        },
        //政府详情
        {
            path: '/government',
            name:'/government',
            component: () => import("@/mobile/Home/governmentDetails.vue")
        },
    ]
})



export default router;